import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Dashboard.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/createwidget',
    name: 'createwidget',
    component: () => import(/* webpackChunkName: "about" */ '../views/Createwidget.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/editwidget/:id',
    name: 'editwidget',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editwidget.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/widgetlist',
    name: 'widgetlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/WidgetList.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/aipng',
    name: 'aipng',
    component: () => import(/* webpackChunkName: "about" */ '../views/aipng/AiPngList.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/createaipng',
    name: 'createaipng',
    component: () => import(/* webpackChunkName: "about" */ '../views/aipng/CreateAiPng.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/editaipng/:id',
    name: 'editaipng',
    component: () => import(/* webpackChunkName: "about" */ '../views/aipng/EidtAiPng.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/:tab?',
    name: 'ad',
    component: () => import(/* webpackChunkName: "about" */ '../views/ad/AdList.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/add-market-ad',
    name: 'marketad',
    component: () => import(/* webpackChunkName: "about" */ '../views/ad/CreateMarketAd.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/createad',
    name: 'createad',
    component: () => import(/* webpackChunkName: "about" */ '../views/ad/CreateAd.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/hotword',
    name: 'hotword',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotWord.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import(/* webpackChunkName: "about" */ '../views/AiWord.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/avatar',
    name: 'avatar',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserAvatar.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/storelist',
    name: 'storelist',
    component: () => import(/* webpackChunkName: "about" */ '../views/store/StoreList.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/createstore',
    name: 'createstore',
    component: () => import(/* webpackChunkName: "about" */ '../views/store/CreateStore.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/ui/:tab?',
    name: 'applist',
    component: () => import(/* webpackChunkName: "about" */ '../views/appui/AppList.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/createappui',
    name: 'createappui',
    component: () => import(/* webpackChunkName: "about" */ '../views/appui/CreateAppui.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/editappuit/:id',
    name: 'editappui',
    component: () => import(/* webpackChunkName: "about" */ '../views/appui/EditAppui.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/updatelogs',
    name: 'updatelogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/updatelogs/UpDatelogs.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/addlogs',
    name: 'addlogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/updatelogs/AddLogs.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/replywords',
    name: 'replywords',
    component: () => import(/* webpackChunkName: "about" */ '../views/replywords/ReplyWords.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/addreplywords',
    name: 'addreplywords',
    component: () => import(/* webpackChunkName: "about" */ '../views/replywords/AddReplyWords.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "about" */ '../views/article/Article.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/addarticle',
    name: 'addarticle',
    component: () => import(/* webpackChunkName: "about" */ '../views/article/AddArticle.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  },
  {
    path: '/cloud',
    name: 'cloud',
    component: () => import(/* webpackChunkName: "about" */ '../views/cloud/cloudDrive.vue'),
    meta: { requiresAuth: true } // 设置需要登录才能访问的路由
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,

  // 控制路由切换时页面自动回到顶部
  scrollBehavior (to, from, savedPosition) {
    // 如果有 savedPosition，直接返回 savedPosition
    if (savedPosition) {
      return savedPosition
    } else {
      // 否则滚动到页面顶部
      return { top: 0 }
    }
  }
})

// 导航守卫
router.beforeEach((to, from, next) => {
  // 判断路由是否需要登录才能访问
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('isLogin')) {
      next('/') // 未登录，重定向到登录页
    } else {
      next()
    }
  } else {
    next() // 不需要登录，继续路由跳转
  }
})

export default router
